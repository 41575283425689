import {Component, HostListener} from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from "ngx-spinner";
import {APIService, Status, User} from "./core/services/api.service";
import {AccountService} from "./core/services/account.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ChatApp';

  constructor(public translate: TranslateService,
              private apiService: APIService,
              private accountService: AccountService,
              private spinner: NgxSpinnerService) {
    translate.addLangs(['en', 'fr', 'ro', 'ru']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr|ro|ru/) ? browserLang : 'en');
  }

  @HostListener("window:beforeunload", ["$event"])
  unloadHandler(event: Event) {
    event.returnValue = false;
    this.updateUserStatus();
  }

  updateUserStatus() {
    let item = sessionStorage.getItem('user')
    let user: User = item ? JSON.parse(item) : null
    if (user.status !== Status.away) {
      this.apiService.UpdateUserStatus(Status.offline);
      this.accountService.setSessionUserStatus(Status.offline);
    }
  }

  prepareRoute(outlet: RouterOutlet): any {
    return outlet && outlet.activatedRouteData;
  }
}
