/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://r25m7lfvizfo7elumkwjbngbqq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-dtdgvdvjd5aqzpvv5na3fsvg5e",
    "aws_cognito_identity_pool_id": "eu-central-1:5b3de7bc-b65c-4efd-8c3b-81c4863c0904",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_mWH0HbluB",
    "aws_user_pools_web_client_id": "6u1jrp8nt5cscecv0boe60aru8",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "chatapp-ui-dev",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://d13mgzpzu6ugnk.cloudfront.net",
    "aws_user_files_s3_bucket": "chat-app-storage154015-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
