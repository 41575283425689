<div class="input-group">
  <div class="input-group-append">
    <span class="input-group-text"><fa-icon [icon]="iconName"></fa-icon></span>
  </div>
  <input
    [class.is-invalid]="ngControl.touched && ngControl.invalid"
    type={{type}}
    class="form-control input_user"
    [formControl]="$any(ngControl).control"
    placeholder={{label}}>
  <div *ngIf="ngControl.control.errors?.required" class="invalid-feedback">Please enter {{(startWithVowelRegEx(label))? 'an': 'a'}} {{label | lowercase}}</div>
  <div *ngIf="ngControl.control.errors?.email" class="invalid-feedback">Please enter a valid email</div>
  <div *ngIf="ngControl.control.errors?.minlength" class="invalid-feedback">
    {{label}} must be at least {{ngControl.control.errors.minlength['requiredLength']}}
  </div>
  <div *ngIf="ngControl.control.errors?.maxlength" class="invalid-feedback">
    {{label}} must be at most {{ngControl.control.errors.maxlength['requiredLength']}}
  </div>
  <div *ngIf="ngControl.control.errors?.isMatching" class="invalid-feedback">
    Passwords do not match
  </div>
</div>
